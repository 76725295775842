export const humanFileSize = (size?: number): string => {
  if (!size) return '0B';

  const i = Math.floor(Math.log2(size) / 10);

  const twoDecimal = Number((size / Math.pow(1000, i)).toFixed(i > 1 ? 2 : 0));
  const result = twoDecimal + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];

  return result;
};
